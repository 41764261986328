<template>
    <Layout>
        <PageHeader :title="title" />
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header bg-light bg-soft filters">
                        <b-form inline class="row gy-2 gx-3 align-items-center">
                            <div class="col-sm-auto">
                                <label>{{ $t("keyword") }} </label>
                                <input
                                    class="form-control"
                                    type="text"
                                    :placeholder="`${$t('search')}...`" />
                            </div>
                            <div class="col-sm-auto">
                                <label>{{ $t("from") }} </label>
                                <input
                                    type="date"
                                    class="form-control"
                                    v-model="filters.from" />
                            </div>
                            <div class="col-sm-auto">
                                <label>{{ $t("to") }}</label>
                                <input
                                    type="date"
                                    class="form-control"
                                    v-model="filters.to" />
                            </div>
                            <div class="col-sm-auto">
                                <label>{{ $t("status") }} </label>
                                <select class="form-control">
                                    <option value="" selected>
                                        {{ $t("all") }}
                                    </option>
                                    <option value="active">
                                        {{ $t("active") }}
                                    </option>
                                    <option value="inactive">
                                        {{ $t("inactive") }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-sm-auto">
                                <button class="btn btn-light mt-4">
                                    {{ $t("search") }}
                                </button>
                            </div>
                            <div class="col text-end">
                                <button
                                    class="btn btn-primary mt-4"
                                    @click="create_modal = true">
                                    {{ $t("create") }}
                                </button>
                            </div>
                            <b-modal
                                v-model="create_modal"
                                @show="focusUsername()"
                                @hidden="reset()"
                                centered
                                :title="$t(`new administrator`)"
                                title-class="font-18"
                                hide-footer>
                                <b-form @submit.prevent="create">
                                    <b-form-group
                                        class="mb-3"
                                        :label="$t('username')"
                                        label-cols-sm="5">
                                        <input
                                            v-model="username"
                                            ref="username"
                                            type="text"
                                            class="form-control"
                                            :class="{
                                                'is-invalid':
                                                    submitted &&
                                                    v$.username.$error,
                                            }" />
                                        <div
                                            v-for="(item, index) in v$.username
                                                .$errors"
                                            :key="index"
                                            class="invalid-feedback">
                                            <span v-if="item.$message">{{
                                                item.$message
                                            }}</span>
                                        </div>
                                    </b-form-group>

                                    <b-form-group
                                        class="mb-3"
                                        :label="$t('password')"
                                        label-cols-sm="5">
                                        <input
                                            v-model="password"
                                            ref="password"
                                            type="password"
                                            class="form-control"
                                            :class="{
                                                'is-invalid':
                                                    submitted &&
                                                    v$.password.$error,
                                            }" />
                                        <div
                                            v-for="(item, index) in v$.password
                                                .$errors"
                                            :key="index"
                                            class="invalid-feedback">
                                            <span v-if="item.$message">{{
                                                item.$message
                                            }}</span>
                                        </div>
                                    </b-form-group>

                                    <b-form-group
                                        class="mb-3"
                                        :label="$t('confirm password')"
                                        label-cols-sm="5">
                                        <input
                                            v-model="confirm_password"
                                            ref="confirm_password"
                                            type="password"
                                            class="form-control"
                                            :class="{
                                                'is-invalid':
                                                    (submitted &&
                                                        v$.confirm_password
                                                            .$error) ||
                                                    (submitted &&
                                                        !passwordMatched),
                                            }" />
                                        <div
                                            v-for="(item, index) in v$
                                                .confirm_password.$errors"
                                            :key="index"
                                            class="invalid-feedback">
                                            <span v-if="item.$message">{{
                                                item.$message
                                            }}</span>
                                        </div>
                                        <div
                                            v-if="
                                                submitted &&
                                                !v$.confirm_password.$errors
                                                    .length &&
                                                !passwordMatched
                                            "
                                            class="invalid-feedback">
                                            <span v-if="!passwordMatched">
                                                {{
                                                    $t(`password doesn't match`)
                                                }}
                                            </span>
                                        </div>
                                    </b-form-group>

                                    <button class="btn btn-warning w-100">
                                        {{ $t("submit") }}
                                    </button>
                                </b-form>
                            </b-modal>
                        </b-form>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table
                                class="table table-bordered table-hover mb-0">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">
                                            {{ $t("username") }}
                                        </th>
                                        <th scope="col">
                                            {{ $t("last logged in at") }}
                                        </th>
                                        <th scope="col">
                                            {{ $t("last logged in IP") }}
                                        </th>
                                        <th scope="col">
                                            {{ $t("actions") }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(r, i) in data.list" :key="r.id">
                                        <td class="text-center">
                                            {{
                                                i +
                                                1 +
                                                (data.current_page - 1) *
                                                    data.per_page
                                            }}
                                        </td>
                                        <td>
                                            {{ r.username }}
                                            <span
                                                v-if="r.status == 1"
                                                class="badge bg-warning"
                                                >Active</span
                                            >
                                            <span
                                                v-if="r.status == 0"
                                                class="badge bg-secondary bg-soft"
                                                >Inactive</span
                                            >
                                        </td>

                                        <td class="text-center">
                                            {{ r.last_login }}
                                        </td>
                                        <td class="text-center">
                                            {{ r.last_login_ip }}
                                        </td>

                                        <td class="text-center">
                                            <div class="button-items">
                                                <b-button
                                                    variant="info"
                                                    size="sm">
                                                    View
                                                </b-button>
                                                <b-button
                                                    variant="danger"
                                                    size="sm">
                                                    Delete
                                                </b-button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <Pagination :data="data" @emitPage="initList" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Pagination from "../../../components/pagination.vue";
import Switches from "vue-switches";

import { required, minLength, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

import { mapActions, mapGetters } from "vuex";
import datetime from "../../../mixins/datetime";
import number from "../../../mixins/number";
import Swal from "sweetalert2";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    page: {
        title: "administrators",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
        Pagination,
        Switches,
    },
    mixins: [datetime, number],
    data() {
        return {
            title: "administrators",
            items: [
                {
                    text: "administrators",
                    href: "/",
                },
            ],
            filters: {
                from: "",
                to: "",
            },
            username: "",
            password: "",
            confirm_password: "",
            data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            create_modal: false,
            submitted: false,
        };
    },
    validations: {
        username: {
            required: helpers.withMessage("required", required),
        },
        password: {
            required: helpers.withMessage("required", required),
        },
        confirm_password: {
            required: helpers.withMessage("required", required),
        },
    },
    computed: {
        passwordMatched() {
            return (
                this.password != "" &&
                this.confirm_password != "" &&
                this.password === this.confirm_password
            );
        },
        createFormValid() {
            return (
                !this.v$.username.$invalid &&
                !this.v$.password.$invalid &&
                !this.v$.confirm_password.$invalid &&
                this.passwordMatched
            );
        },
    },
    methods: {
        ...mapActions("administrator", {
            adminGetList: "getList",
        }),
        async initList(p) {
            var pl = {
                page_no: p,
            };
            const data = await this.adminGetList(pl);
            this.data.list = data.data;
            this.data.links = data.links;
            this.data.current_page = data.current_page;
            this.data.per_page = data.per_page;
            this.data.last_page = data.last_page;
        },
        async create() {
            this.submitted = true;
            this.v$.$touch();
            if (!this.createFormValid) {
                return;
            } else {
                this.create_modal = false;
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: this.$t("new admin has been created"),
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        },
        focusUsername() {
            setTimeout(() => {
                this.$nextTick(() => this.$refs.username.focus());
            }, 500);
        },
        reset() {
            this.submitted = false;
            this.username = "";
            this.password = "";
            this.confirm_password = "";
        },
    },
    mounted() {
        this.filters.from = this.firstDateOftheMonth();
        this.filters.to = this.dateYmD(new Date());
        this.initList(1);
    },
};
</script>
