<template>
    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div
                class="page-title-box d-flex align-items-center justify-content-between">
                <h4 class="mb-0 font-size-18">{{ $t(title) }}</h4>

                <div class="page-title-right">
                    <!-- <b-breadcrumb :items="items" class="m-0"></b-breadcrumb> -->
                    <!-- <button
                        v-if="backPath"
                        class="btn btn-link btn-sm"
                        @click.prevent="goBack()">
                        <i class="bx bx-arrow-back"></i>
                        {{ $t("Go Back") }}
                    </button> -->
                </div>
            </div>
        </div>
    </div>
    <!-- end page title -->
</template>

<script>
export default {
    components: {},
    props: {
        title: {
            type: String,
            default: "",
        },
        backPath: {
            type: String,
            default: "",
        },
        items: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    methods: {
        goBack() {
            this.$router.push({
                path: this.backPath,
                query: this.$route.query,
            });
        },
    },
};
</script>
